<template>
  <layout>
    <div class="container">
      <div class="title">
        寄件下单
      </div>
      <div class="success">
        <img src="https://cdn.kuaidi100.com/images/m/sntInvite/icon-checked.png"/></br>
        <span>下单成功</span>
      </div>
      <div class="opt">
        <button @click="goList" style="background-color: #fff;">查看已寄出订单</button>
        <button @click="goBack">再下一单</button>
      </div>
    </div>
  </layout>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: "sntSuccess",
  components: {
    
  },
  filters: {
    
  },
  data() {
    return {
      sign: ''
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    goBack() {
      // this.$router.back()
      window.location.back()
    },
    goList() {
      // window.location.replace = 'http://10.240.21.65:8082/sntinvite'
      this.$router.replace({
        name: "sntOrderList",
        query: {
          sign: this.$route.query.sign,
          phone: this.userInfo.mobile
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
    })
  }
}
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  background: #f7f7f7;
  height: 100%;
  .title {
    width: 100%;
    font-size: 1.0625rem;
    color: #333;
    text-align: center;
    padding: 1.375rem 0;
    font-weight: bold;
  }
  .success {
    text-align: center;
    margin-top: 3rem;
    img {
      width: 3.125rem;
      height: 3.125rem;
    }
    span {
      font-size: 1.25rem;
      color: #3D3D3D;
    }
  }
  .opt {
    text-align: center;
    margin-top: 3.3rem;
    button {
      min-width: 7.5rem;
      line-height: 2.2rem;
      font-size: 0.9375rem;
      border-radius: 0.3125rem;
      text-align: center;
      border: 1px solid #0082FA;
      margin: 0 0.9375rem;
      padding: 2px 0;
      &:first-child {
        color: #0082FA;
        background: #fff;
      }
      &:last-child {
        color: #fff;
        background: #0082FA;
      }
    }
  }
}
</style>